import { alpha } from '@mui/material/styles';
import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles<void, 'icon' | 'iconRight' | 'largeIcon' | 'filtersCounter' | 'hasIcon'>()(
	(theme, _, classes) => ({
		button: {
			border: 'none',
			fontFamily: theme.typography.h1.fontFamily,
			fontWeight: 400,
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 1.25,
			letterSpacing: 'normal',
			textAlign: 'center',
			outline: 'none',
			display: 'flex',
			maxWidth: '100%',
			alignItems: 'center',
			touchAction: 'manipulation',

			[`&.${classes.icon}`]: {
				width: theme.spacing(2),
				height: theme.spacing(2),
				flex: '0 0 auto',
			},

			[`&.${classes.hasIcon}`]: {
				justifyContent: 'space-between',
				textAlign: 'left',
			},

			'&:hover:enabled': {
				cursor: 'pointer',
			},

			'&:disabled': {
				opacity: 0.5,
			},

			[`& span.${classes.filtersCounter}`]: {
				fontFamily: theme.typography.fontFamily,
				textAlign: 'center',
				color: theme.palette.common.white,
				borderRadius: '50%',
				backgroundColor: theme.palette.primary.main,
				marginLeft: '0.5em',
				display: 'inline-block',
				fontSize: '0,75rem',
				width: '24px',
				height: '24px',
				lineHeight: '24px',
				padding: '0',
			},
		},

		large: {
			borderRadius: theme.shape.borderRadius / 2,
			padding: `7px ${theme.spacing(2)}`,
			fontSize: theme.spacing(2),
			borderWidth: '2px',
			borderStyle: 'solid',
			lineHeight: 1.25,

			'& span': {
				padding: theme.spacing(1, 0),
			},

			[`&.${classes.icon}.${classes.largeIcon}`]: {
				width: theme.spacing(3),
				height: theme.spacing(3),
			},

			[`&.${classes.icon}+ span`]: {
				paddingLeft: theme.spacing(2),
			},

			[`& span + .${classes.icon}`]: {
				paddingRight: 0,
				paddingLeft: theme.spacing(2),
			},
		},

		medium: {
			borderRadius: theme.shape.borderRadius / 2,
			fontSize: theme.spacing(1.75),
			padding: '2px 10px',
			borderWidth: '2px',
			borderStyle: 'solid',
			lineHeight: 1.23,

			'& span': {
				padding: '2px 0 3px 0',
			},

			[`& .${classes.icon}.${classes.largeIcon}`]: {
				width: theme.spacing(2.5),
				height: theme.spacing(2.5),
			},

			[`& .${classes.icon} + span`]: {
				paddingLeft: '10px',
			},

			[`& span + .${classes.icon}`]: {
				paddingRight: 0,
				paddingLeft: '10px',
			},
		},

		small: {
			borderRadius: theme.shape.borderRadius / 4,
			padding: '1px 8px',
			fontSize: theme.spacing(1.25),
			borderWidth: '2px',
			borderStyle: 'solid',
			lineHeight: 1.6,

			'& span': {
				padding: '1px 0',
			},

			[`& .${classes.icon}.${classes.largeIcon}`]: {
				width: theme.spacing(2.2),
				height: theme.spacing(2.2),
			},

			[`& .${classes.icon} + span`]: {
				paddingLeft: '5px',
			},

			[`& span + .${classes.icon}`]: {
				paddingRight: 0,
				paddingLeft: '5px',
			},
		},

		primary: {
			borderColor: theme.palette.secondary.main,
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.common.white,

			'&:enabled:not(disabled):hover': {
				borderColor: theme.palette.secondary.light,
			},

			'&:enabled:not(disabled):active': {
				borderColor: theme.palette.secondary.light,
			},

			[`& span.${classes.filtersCounter}`]: {
				backgroundColor: theme.palette.secondary.light,
			},
		},

		secondary: {
			fontSize: theme.spacing(1.75),
			border: 'solid 1px transparent',
			backgroundColor: theme.palette.grey[200],
			color: theme.palette.grey[600],
			position: 'relative',

			'&:enabled:not(disabled):hover': {
				borderColor: theme.palette.common.white,
				backgroundColor: theme.palette.grey[200],
				color: theme.palette.grey[900],
			},

			'&:enabled:not(disabled):active': {
				borderColor: 'transparent',
				color: theme.palette.grey[900],
			},

			'&:disabled': {
				opacity: 0.5,
				pointerEvents: 'none',
			},
		},

		default: {
			borderColor: 'transparent',
			backgroundColor: 'transparent',
			color: theme.palette.secondary.main,

			'&:enabled:not(disabled):hover': {
				backgroundColor: theme.palette.common.white,
				borderColor: theme.palette.common.white,
			},

			'&:enabled:not(disabled):active': {
				backgroundColor: theme.palette.common.white,
				borderColor: theme.palette.common.white,
			},
		},

		'secondary-small': {
			lineHeight: 1.8,

			'&:not(disabled)': {
				'&::before': {
					borderRadius: theme.shape.borderRadius,
				},

				'&::after': {
					borderRadius: theme.shape.borderRadius / 4,
				},
			},
		},

		price: {
			width: '100%',
			justifyContent: 'space-between',
			fontFamily: theme.typography.body1.fontFamily,
			fontWeight: 'bold',
		},

		priceTag: {
			fontSize: theme.spacing(2.75),
			marginLeft: '6px',
			marginRight: '6px',
			verticalAlign: 'middle',
		},

		outlined: {
			background: 'transparent',
			border: `1px solid ${theme.palette.primary.main}`,
			borderRadius: theme.shape.borderRadius / 4,
			color: theme.palette.primary.main,
			fontSize: theme.spacing(2),
			fontWeight: 'bold',
			padding: '0.7rem 1rem',

			'&:hover': {
				borderColor: theme.palette.primary.dark,
				color: theme.palette.primary.dark,
			},
		},

		tiny: {
			borderRadius: theme.shape.borderRadius / 2,
			fontSize: theme.spacing(1.2),
			padding: '3px 6px',
			position: 'relative',
			border: theme?.borders?.default,
			backgroundColor: theme.palette.grey[200],
			color: theme.palette.grey[600],

			'&:hover': {
				borderColor: theme.palette.common.white,
				color: theme.palette.grey[900],
			},

			'&:active': {
				borderColor: theme.palette.grey[200],
				color: theme.palette.grey[900],
			},

			'&::before': {
				position: 'absolute',
				content: '""',
				top: '-3px',
				left: '-3px',
				right: '-3px',
				bottom: '-3px',
				borderRadius: theme.shape.borderRadius,
			},

			'&::after': {
				position: 'absolute',
				content: '""',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				borderRadius: theme.shape.borderRadius,
			},

			'&:disabled': {
				pointerEvents: 'none',
				opacity: 0.5,
			},

			[`& .${classes.icon} + span`]: {
				paddingLeft: '5px',
			},

			[`& span + .${classes.icon}`]: {
				paddingRight: 0,
				paddingLeft: '5px',
			},
		},

		link: {
			color: theme.palette.common.black,
			padding: 0,
			fontSize: theme.spacing(1.5),
			background: 'none',
			textTransform: 'lowercase',

			'& span': {
				position: 'relative',
				paddingTop: '2px',
				marginBottom: '4px',
				zIndex: 1,

				'&::after': {
					content: '""',
					position: 'absolute',
					bottom: '1px',
					zIndex: -1,
					display: 'block',
					width: '100%',
					borderBottom: `4px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
					transition: 'border-bottom 0.2s',
				},
			},

			[`& .${classes.icon}`]: {
				fill: alpha(theme.palette.primary.main, 0.8),
				transition: 'fill 0.2s',
				marginRight: theme.spacing(1.5),
			},

			[`& .${classes.iconRight}`]: {
				fill: alpha(theme.palette.primary.main, 0.8),
				transition: 'fill 0.2s',
				marginLeft: theme.spacing(1.5),
			},

			[`& .${classes.largeIcon}`]: {
				width: theme.spacing(2.5),
				height: theme.spacing(2.5),
			},

			'&:hover': {
				'& span::after': {
					display: 'block',
					borderBottom: `4px solid ${theme.palette.secondary.main}`,
				},

				[`& .${classes.icon}`]: {
					fill: theme.palette.primary.main,
				},
				[`& .${classes.iconRight}`]: {
					fill: theme.palette.primary.main,
				},
			},
		},

		buttonLink: {
			textDecoration: 'none',
			boxShadow: 'none !important',

			'&:hover': {
				boxShadow: 'none',
			},
		},

		'capital-link': {
			color: theme.palette.common.black,
			padding: 0,
			fontSize: theme.spacing(1.5),
			background: 'none',

			'& span': {
				position: 'relative',
				paddingTop: '2px',
				marginBottom: '4px',
				zIndex: 1,

				'&::after': {
					content: '""',
					position: 'absolute',
					bottom: '1px',
					zIndex: -1,
					display: 'block',
					width: '100%',
					borderBottom: `4px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
					transition: 'border-bottom 0.2s',
				},
			},

			[`& .${classes.icon}`]: {
				fill: alpha(theme.palette.primary.main, 0.8),
				transition: 'fill 0.2s',
				marginRight: theme.spacing(1.5),
			},

			[`& .${classes.iconRight}`]: {
				fill: alpha(theme.palette.primary.main, 0.8),
				transition: 'fill 0.2s',
				marginLeft: theme.spacing(1.5),
			},

			[`& .${classes.largeIcon}`]: {
				width: theme.spacing(2.5),
				height: theme.spacing(2.5),
			},

			'&:hover': {
				'& span::after': {
					display: 'block',
					borderBottom: `4px solid ${theme.palette.secondary.main}`,
				},

				[`& .${classes.icon}`]: {
					fill: theme.palette.primary.main,
				},
				[`& .${classes.iconRight}`]: {
					fill: theme.palette.primary.main,
				},
			},
		},

		round: {
			padding: theme.spacing(2),
			backgroundColor: theme.palette.grey[200],
			borderRadius: '50%',

			[`& .${classes.icon}`]: {
				width: theme.spacing(1.5),
				height: theme.spacing(1.5),
			},
		},

		action: {
			backgroundColor: alpha(theme.palette.secondary.main, 0.05),
			border: `1px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
			borderRadius: theme.shape.borderRadius,
			padding: theme.spacing(2.5),
			fontSize: theme.spacing(1.5),
			lineHeight: theme.spacing(2.5),
			color: theme.palette.common.black,
			fontFamily: theme.typography.body1.fontFamily,
			fontWeight: 700,
			position: 'relative',

			'& span': {
				marginBottom: 0,
			},

			'&:after': {
				position: 'absolute',
				top: '-2px',
				left: '-2px',
				right: '-2px',
				bottom: '-2px',
				borderRadius: theme.shape.borderRadius,
				border: 'solid 3px',
			},

			'&:hover': {
				border: `1px solid ${theme.palette.secondary.main}`,

				'&:after': {
					content: '""',
					borderColor: alpha(theme.palette.secondary.main, 0.25),
				},
			},

			'&:active': {
				'&:after': {
					borderColor: alpha(theme.palette.secondary.main, 0.5),
				},
			},

			'&:disabled': {
				opacity: 0.5,
				pointerEvents: 'none',
			},

			[`& .${classes.icon}`]: {
				marginRight: theme.spacing(2.5),
				width: theme.spacing(2.2),
				height: theme.spacing(2.2),
				color: theme.palette.primary.main,
			},
		},

		'action-tiny': {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.secondary.main,
			border: `1px solid ${theme.palette.secondary.main}`,
			borderRadius: theme.shape.borderRadius - 2,
			padding: theme.spacing(0.375, 1, 0.375, 0.75),
			fontSize: theme.spacing(1.75),
			lineHeight: theme.spacing(2),
			[`& .${classes.icon}`]: {
				width: theme.spacing(1.125),
				height: theme.spacing(1.125),
				marginRight: theme.spacing(2),
			},
			'&:enabled:not(disabled):hover': {
				borderColor: theme.palette.buttons.main,
				color: theme.palette.buttons.main,
			},

			'&:enabled:not(disabled):active': {
				borderColor: theme.palette.buttons.main,
				color: theme.palette.buttons.main,
			},
		},

		roundWide: {
			background: theme.palette.common.white,
			border: `1px solid ${theme.palette.grey[500]}`,
			borderRadius: theme.spacing(2.2),
			height: theme.spacing(4.2),
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(1, 2),
			color: theme.palette.common.black,

			'&:hover': {
				background: theme.palette.grey[50],
			},
		},

		icon: {},
		hasIcon: {},
		largeIcon: {},
		iconRight: {},
		filtersCounter: {},
	}),
);

export default useStyles;
